<template>
    <k-layout>
        <div class="questionaire-page">
            <div class="tw-bg-gray-900 tw-w-full tw-py-24 tw-text-gray-50 tw-text-center">
                <h1 class="tw-heading-1">{{ $t('questionaire.title') }}</h1>
                <p v-if="selectedProfession" class="tw-heading-4 tw-mt-4">
                    {{ selectedProfession.displayString }} - {{ $t('global.resume') }}
                </p>
            </div>
            <div class="questionaire-container">
                <div class="kod-programmer-questionaire kod-shadow">
                    <h3>
                        {{ $t('questionaire.subtitles.evaluate_resume') }}
                    </h3>
                    <hr />
                    <kodCV v-model="cvObject" class="kod-cv-wrapper" :cvRequired="true" :formInvalid="formInvalid" />
                    <div class="questionaire-actions">
                        <hr class="ultra-special-hr" />
                        <v-expand-transition>
                            <div v-show="formInvalid && isFormValid()" class="form-error-message">
                                <p>
                                    {{ $t('global.form_errors.check_entered_information') }}
                                </p>
                            </div>
                        </v-expand-transition>
                        <k-button
                            id="ga_questionaire_cv-upload"
                            :block="isMobile"
                            @click="sendForm"
                            :loading="$store.state.QUESTIONAIRE.loading"
                            :disabled="$store.state.QUESTIONAIRE.loading"
                        >
                            {{ $t('global.actions.complete') }}
                        </k-button>
                    </div>
                </div>
            </div>
        </div>
    </k-layout>
</template>

<script>
import kodCV from "../components/kod-cv";
import responsiveMixin from "./../responsiveMixin";
import KButton from '@web/components/core/k-button'
import { mapGetters } from 'vuex'

export default {
    mixins: [responsiveMixin],
    name: "CVQuestionairePage",
    components: {
        KButton,
        kodCV
    },
    data() {
        return {
            cvObject: {
                name: null,
                email: null,
                cvURL: null,
                file: null
            },
            formInvalid: false
        };
    },
    computed: {
        ...mapGetters({
            professions: 'ENUMS/getProfessionsWithoutOther',
            profile: 'USER/getUserProfile'
        }),
        selectedProfession() {
            if (!this.professions.length) {
                return {}
            }

            const { queryProfession } = this.$route.params

            if (!queryProfession) {
                return null
            }

            const selectedProfession = this.professions.find(item => item.value === queryProfession)

            if (!selectedProfession) {
                return null
            }

            return selectedProfession
        },
    },
    methods: {
        sendForm() {
            if (this.isFormValid()) this.formInvalid = true;
            else {
                this.$store.dispatch("QUESTIONAIRE/saveCVObjectToDB", this.cvObject);
            }
        },
        isFormValid() {
            return (
                !this.cvObject.file ||
                !this.cvObject.name ||
                this.cvObject.name === "" ||
                !this.cvObject.email ||
                this.cvObject.email === "" ||
                !this.validateMail(this.cvObject.email)
            );
        },
        validateMail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            return re.test(email);
        },
        redirect() {
            this.$router.push({ path: "/adekvatny-plat" });
        }
    },
    watch: {
        selectedProfession(newValue) {
            if (newValue === null) {
                this.redirect()
            }
        },
        "profile": {
            deep: true,
            immediate: true,
            handler(profile) {
                if(profile) {
                    setTimeout(() => {
                        this.cvObject = {
                            email: profile.email,
                            name: [profile.firstName, profile.lastName].filter(Boolean).join(" ")
                        }
                    }, 100)
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.kod-programmer-questionaire {
    @include margin-top-multiplier(4);
    @include padding-top-multiplier(7);
    @include padding-bottom-multiplier(4);
    @include padding-left-multiplier(8);
    @include padding-right-multiplier(8);

    width: 100%;
    max-width: 51.5rem;

    @media (max-width: 63.938rem) {
        @include padding-top-multiplier(4);
        @include padding-bottom-multiplier(4);
        @include padding-left-multiplier(2);
        @include padding-right-multiplier(2);
    }

    background: white;
    p.small-text {
        &:first-child {
            cursor: pointer;
            @include margin-bottom-multiplier(1);
            &:hover {
                text-decoration: underline;
            }
        }
    }
    hr {
        border: unset;
        border-bottom: 0.063rem solid $koderia-border-grey;
    }
    h3 {
        @include margin-bottom-multiplier(2);
    }
    p.body-text {
        @include margin-bottom-multiplier(6);
    }
    // overriding margins
    .custom-autocomplete {
        margin-top: 0;
        padding-top: 0 !important;
    }
    // intro - second step
    .kod-prax-technology-question {
        @include margin-top-multiplier(8);
        &.remove-margin {
            @include margin-top-multiplier(0);
        }
        p.title-text {
            > span {
                color: $koderia-green;
            }
        }

        .v-slider--horizontal {
            margin-left: 0;
            margin-right: 0;
        }
    }
    // additional questions for tester/programmer
    .bonus-questions {
        &.add-margin {
            @include margin-top-multiplier(8);
        }

        p.title-text {
            @include margin-bottom-multiplier(2);
        }
        .kod-radio-group {
            @include margin-bottom-multiplier(7);
            padding-top: 0;

            &:last-child {
                @include margin-bottom-multiplier(0);
            }
            &:last-child.remove-no-margin {
                @include padding-bottom-multiplier(7);
            }
            label {
                color: $koderia-black;
            }
            .v-input__slot {
                margin-bottom: 0;
            }
            .v-messages {
                display: none;
            }
        }
    }
    h4 {
        @include margin-bottom-multiplier(1);
    }
    .questionaire-contact-info {
        @include margin-top-multiplier(3);
        @include margin-bottom-multiplier(6);

        display: flex;
        flex-direction: row;

        > div {
            width: 50%;
            &:first-child {
                @include padding-right-multiplier(2);
            }
            &:last-child {
                @include padding-left-multiplier(2);
            }
        }

        @media (max-width: 63.938rem) {
            flex-direction: column;

            > div {
                width: 100%;
                &:first-child {
                    @include padding-right-multiplier(0);
                }
                &:last-child {
                    @include padding-top-multiplier(2);
                    @include padding-left-multiplier(0);
                }
            }
        }
    }
    .kod-gdpr-checkbox {
        @include margin-top-multiplier(3);
    }
    .questionaire-actions {
        text-align: center;
        hr {
            @include margin-top-multiplier(6);
        }
        .new-kod-button {
            @include margin-top-multiplier(4);
            padding: 0.625rem 5.5rem !important;
        }
    }
}
.kod-programmer-questionaire {
    width: 100%;
    max-width: 51.5rem;
}
</style>
