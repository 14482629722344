<template>
    <v-radio v-bind="$attrs" v-on="$listeners" :class="className" :ripple="false" :color="$attrs.value ? colors.kodGreen : colors.kodBlack" />
</template>

<script>
import colors from "@web/sass/variables.scss";

export default {
    computed: {
        className() {
            let className = "body-text";
            return `${className} ${this.class}`;
        },
        colors: function() {
            return {
                kodGreen: colors.kodGreen,
                kodBlack: colors.kodBlack
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep.v-radio {
    .v-label {
        color: $kod-black;
        font-size: 0.875rem;
    }
    .v-input--selection-controls__input .v-icon {
        color: $kod-black;
    }
}
</style>
