<template>
    <v-layout class="kod-cv" column>
        <div class="kod-cv-text-input-container">
            <k-input
                :type="'text'"
                :title="$t('global.inputs.first_name')"
                :placeholder="$t('global.inputs.examples.name')"
                :required="true"
                :disabled="false"
                :emptyNotice="formInvalid"
                v-model="value.name"
            />

            <k-input
                :type="'email'"
                :title="$t('global.inputs.email')"
                :placeholder="$t('global.inputs.examples.email')"
                :required="true"
                :disabled="false"
                :emptyNotice="formInvalid"
                v-model="value.email"
            />
        </div>

        <div class="kod-cv-upload tw-self-start">
            <p :class="!value.file && formInvalid ? 'title-text form-invalid' : 'title-text'">{{ $t('questionaire.actions.upload_resume') }} <span>*</span></p>

            <input
                ref="fileInputRef"
                @change="handleInputChange()"
                type="file"
                name="mobile-file"
                id="fileInput"
                accept=".pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style="display: none;"
            />

            <k-button color="regular" @click="fileUploadButtonAction()" id="dragArea" ref="droparea">
                <k-icon name="attach" />
                {{ $t('global.actions.upload_file') }}
            </k-button>

            <p v-if="value.file" class="extra-small-text">
                {{ value.file.name }}
                <v-btn icon @click="discardCV" small class="close-icon">
                    <k-icon name="close" size="14" />
                </v-btn>
            </p>
        </div>
    </v-layout>
</template>

<script>
import FileDropArea from "../addons/filedrop/FileDropArea";

export default {
    name: "kod-cv",
    props: {
        formInvalid: Boolean,
        cvRequired: Boolean,
        value: Object
    },
    methods: {
        handleInputChange() {
            const file = this.$refs.fileInputRef.files.item(0);

            this.updateFile(file)
        },
        fileUploadButtonAction() {
            this.$refs.fileInputRef.click();
        },
        discardCV() {
            this.value.file = null;
            this.$refs.fileInputRef.value = "";
        },
        updateFile(file) {
            this.$emit('input', { ...this.value, file })
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler(val) {
                console.log(val)
            }
        }
    },
    mounted() {
        this.fileDropArea = new FileDropArea(
            this.$refs.droparea.$el,
            files => {
                this.updateFile(files[0]);
            }
            /* eslint-disable no-console */
            // error => {console.log(error)}
        ).bind();
    },
    beforeDestroyed() {
        this.fileDropArea.unbind();
    }
};
</script>
<style lang="scss">
@import "@web/sass/variables.scss";
.kod-cv {
    cursor: pointer;

    .title-text {
        > span {
            color: $koderia-red;
        }
    }
    .green-text {
        color: $koderia-green-text;
    }
    .kod-cv-file-input {
        @include padding-multiplier(6);

        @include margin-bottom-multiplier(5);

        border: 0.063rem dashed $koderia-light-grey;
        border-radius: 0.125rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        > img {
            @include margin-bottom-multiplier(4);
        }
        > .body-text {
            @include margin-bottom-multiplier(2);
        }
    }
    .kod-cv-text-input-container {
        @include margin-top-multiplier(3);

        display: flex;
        flex-direction: row;

        > div {
            width: 50%;
            &:first-child {
                @include padding-right-multiplier(2);
            }
            &:last-child {
                @include padding-left-multiplier(2);
            }
        }

        @media (max-width: 63.938rem) {
            flex-direction: column;
            > div {
                width: 100%;
                &:first-child {
                    @include padding-right-multiplier(0);
                }
                &:last-child {
                    @include padding-top-multiplier(3);
                    @include padding-left-multiplier(0);
                }
            }
        }
    }
    .kod-cv-upload {
        @include margin-top-multiplier(4);
        p.title-text {
            @include margin-bottom-multiplier(2);
            &.form-invalid {
                color: $koderia-red-alternative;
            }
        }
        .extra-small-text {
            @include padding-multiplier(1);
            @include margin-top-multiplier(2);

            background: $koderia-border-grey;
            color: $koderia-black;

            border-radius: 0.125rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>
