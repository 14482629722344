<template>
    <k-layout>
        <div class="questionaire-page">
            <div class="tw-bg-gray-900 tw-w-full tw-py-24 tw-text-gray-50 tw-text-center">
                <h1 class="tw-heading-1">{{ $t('questionaire.title') }}</h1>
                <p class="tw-heading-4 tw-mt-4">
                    {{ selectedProfession && selectedProfession.displayString }}
                </p>
            </div>
            <div class="questionaire-container">
                <div class="kod-programmer-questionaire kod-shadow">
                    <div v-if="step == 1">
                        <h3>{{ $t('global.step') }} 1/3</h3>
                        <p class="body-text">
                            {{ $t('questionaire.descriptions.first_step') }}
                        </p>
                        <v-layout column class="kod-questionaire-window-content third">
                            <p class="title-text programmer-technologies-title">
                                {{ $t('questionaire.subtitles.master_technologies') }}
                            </p>
                            <!-- class="custom-autocomplete" -->
                            <k-autocomplete-chips
                                v-model="programmerObject.technologiesKnown"
                                :items="$store.state.QUESTIONAIRE.technologies"
                                item-text="name"
                                item-value="name"
                                :placeholder="$t('global.inputs.technology')"
                                id="myAutocomplete"
                                @input="autocompleteInput = null"
                                :search-input.sync="autocompleteInput"
                                autocomplete="off"
                            />

                            <div class="kod-prax-technology-question">
                                <p class="title-text">
                                    {{ $t('questionaire.subtitles.total_experience') }}
                                </p>
                                <v-slider
                                    v-model="programmerObject.overalPraxis"
                                    :tick-labels="['‹1', '1', '2', '3', '4', '5', '6›']"
                                    :max="6"
                                    step="1"
                                    ticks="always"
                                    tick-size="0"
                                    :color="colors.kodBlack"
                                    :track-color="colors.kodWhitesmoke"
                                />
                            </div>
                            <div v-for="(tech, index) in programmerObject.technologiesKnown" :key="index" class="kod-prax-technology-question">
                                <p class="title-text">
                                    {{ $t('questionaire.subtitles.master_experience') }} <span>{{ tech }}</span>
                                </p>
                                <v-slider
                                    :tick-labels="['‹1', '1', '2', '3', '4', '5', '6›']"
                                    :max="6"
                                    step="1"
                                    ticks="always"
                                    tick-size="0"
                                    :color="colors.kodBlack"
                                    :track-color="colors.kodWhitesmoke"
                                    v-model="programmerObject.technologiesPraxis.filter(e => e.name == tech)[0].praxis"
                                />
                            </div>
                            <div class="questionaire-actions">
                                <hr />
                                <k-button color="secondary" id="ga_questionaire_programmer-step-1" :block="isMobile" @click="step = 2">
                                    {{ $t('global.actions.continue') }}
                                </k-button>
                            </div>
                        </v-layout>
                    </div>
                    <div v-if="step == 2">
                        <p class="small-text" @click="step = 1">
                            ‹ {{ $t('global.actions.back') }}
                        </p>
                        <h3>{{ $t('global.step') }} 2/3</h3>
                        <p class="body-text">
                            {{ $t('questionaire.descriptions.second_step') }}
                        </p>
                        <v-layout column class="kod-questionaire-window-content fourth">
                            <div class="bonus-questions">
                                <p class="title-text">
                                    {{ $t('questionaire.subtitles.leadership_experience') }}
                                </p>
                                <v-radio-group v-model="programmerObject.isTeamleader" row class="kod-radio-group">
                                    <k-radio :label="$t('global.yes')" :value="true" :color="colors.kodGreen" class="body-text" />
                                    <k-radio :label="$t('global.no')" :value="false" :color="colors.kodBlack" class="body-text" />
                                </v-radio-group>
                                <p class="title-text">
                                    {{ $t('questionaire.subtitles.domain_experience') }}
                                    <span>{{ $t('questionaire.subtitles.domain_experience_examples') }}</span>
                                </p>
                                <v-radio-group v-model="programmerObject.workedDomain" row class="kod-radio-group">
                                    <k-radio :label="$t('global.yes')" :value="true" :color="colors.kodGreen" class="body-text" />
                                    <k-radio :label="$t('global.no')" :value="false" :color="colors.kodBlack" class="body-text" />
                                </v-radio-group>
                                <p class="title-text">
                                    {{ $t('questionaire.subtitles.software_architect_experience') }}
                                </p>
                                <v-radio-group v-model="programmerObject.isArchitect" row class="kod-radio-group">
                                    <k-radio :label="$t('global.yes')" :value="true" :color="colors.kodGreen" class="body-text" />
                                    <k-radio :label="$t('global.no')" :value="false" :color="colors.kodBlack" class="body-text" />
                                </v-radio-group>
                            </div>
                            <div class="questionaire-actions">
                                <hr />
                                <k-button color="secondary" id="ga_questionaire_programmer-step-2" :block="isMobile" @click="step = 3">
                                    {{ $t('global.actions.continue') }}
                                </k-button>
                            </div>
                        </v-layout>
                    </div>
                    <div v-if="step == 3">
                        <p class="small-text" @click="step = 2">
                            ‹ {{ $t('global.actions.back') }}
                        </p >
                        <h3>{{ $t('global.step') }} 3/3</h3>
                        <p class="body-text">
                            {{ $t('questionaire.descriptions.third_step') }}
                        </p>
                        <v-layout column class="kod-questionaire-window-content fourth">
                            <h4>
                                {{ $t('questionaire.subtitles.about_you') }}
                            </h4>
                            <hr />
                            <div class="questionaire-contact-info">
                                <div>
                                    <k-input
                                        type="text"
                                        :title="$t('global.inputs.first_name')"
                                        :placeholder="$t('global.inputs.examples.name')"
                                        :required="true"
                                        v-model="programmerObject.name"
                                        :emptyNotice="formInvalid"
                                    />
                                </div>
                                <div>
                                    <k-input
                                        type="email"
                                        :title="$t('global.inputs.email')"
                                        :placeholder="$t('global.inputs.examples.email')"
                                        :required="true"
                                        v-model="programmerObject.email"
                                        :emptyNotice="formInvalid"
                                    />
                                </div>
                            </div>
                            <div class="questionaire-actions">
                                <hr />
                                <v-expand-transition>
                                    <div v-show="formInvalid && isFormValid()" class="form-error-message">
                                        <p>
                                            {{ $t('global.form_errors.check_entered_information') }}
                                        </p>
                                    </div>
                                </v-expand-transition>
                                <k-button
                                    color="primary"
                                    id="ga_questionaire_programmer-step-3"
                                    :block="isMobile"
                                    @click="sendForm"
                                    :loading="$store.state.QUESTIONAIRE.loading"
                                    :disabled="$store.state.QUESTIONAIRE.loading"
                                >
                                    {{ $t('global.actions.complete') }}
                                </k-button>
                            </div>
                        </v-layout>
                    </div>
                </div>
            </div>
        </div>
    </k-layout>
</template>

<script>
import colors from "../sass/variables.scss";
import questionaireCalculator from "../addons/questionaire_calculator";
import responsiveMixin from "./../responsiveMixin";
import { DEFAULT_LOCALE, getRouteLocale } from '@web/constants/language'
import { mapGetters } from 'vuex'

export default {
    mixins: [responsiveMixin],
    name: "ProgrammerFormQuestionairePage",
    data() {
        return {
            step: 1,
            selectedTechnologie: null,
            autocompleteInput: null,
            programmerObject: {
                name: "",
                email: "",
                overalPraxis: 0,
                technologiesKnown: [],
                technologiesPraxis: [],
                isTeamleader: false,
                isArchitect: false,
                workedDomain: false,
                gdpr: {
                    gdprValue: true,
                    newsletterValue: true
                }
            },
            formInvalid: false
        };
    },
    methods: {
        sendForm() {
            if (this.isFormValid()) this.formInvalid = true;
            else {
                questionaireCalculator.programmerCalculator.isInputValid(this.programmerObject);
                questionaireCalculator.programmerCalculator.processInput(this.programmerObject);
                questionaireCalculator.programmerCalculator.computeWage(this.programmerObject);

                this.$store.dispatch("QUESTIONAIRE/setFormResultsData", this.programmerObject);
                this.$store.dispatch("QUESTIONAIRE/saveProgrammerToDB", this.programmerObject);
            }
        },
        isFormValid() {
            return (
                !this.programmerObject.name ||
                this.programmerObject.name === "" ||
                !this.programmerObject.email ||
                this.programmerObject.email === "" ||
                !this.validateMail(this.programmerObject.email)
            );
        },
        validateMail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
            return re.test(email);
        },
        redirect() {
            this.$router.push({ path: this.pathPrefix + "/adekvatny-plat" })
        }
    },
    created() {
        this.$store.dispatch("QUESTIONAIRE/initiateGetTechnologies");
    },
    computed: {
        ...mapGetters({
            professions: 'ENUMS/getProfessionsWithoutOther',
            profile: 'USER/getUserProfile'
        }),
        pathPrefix() {
            const locale = getRouteLocale()

            return locale === DEFAULT_LOCALE ? '' : `/${locale}`
        },
        colors: function() {
            return {
                kodBlack: colors.kodBlack,
                kodWhitesmoke: colors.kodWhitesmoke,
                kodGreen: colors.kodGreen
            };
        },
        sendButtonDisabled: function() {
            return this.programmerObject.name == "" || this.programmerObject.email == "" || !this.programmerObject.gdpr.gdprValue;
        },
        localTechnologiesPraxis: function() {
            return this.$store.state.QUESTIONAIRE.technologiesPraxis;
        },
        selectedProfession() {
            if (!this.professions.length) {
                return {}
            }

            const { programmer } = this.$route.params

            if (!programmer) {
                return null
            }

            const selectedProfession = this.professions.find(item => item.value === programmer)

            if (!selectedProfession) {
                return null
            }

            return selectedProfession
        },
    },
    watch: {
        localTechnologiesPraxis: function() {
            this.programmerObject.technologiesPraxis = this.localTechnologiesPraxis;
        },
        step: function() {
            this.$vuetify.goTo(0);
        },
        selectedProfession(newValue) {
            if (newValue === null) {
                this.redirect()
            }
        },
        "profile": {
            immediate: true,
            handler(profile) {
                if (!profile) {
                    return
                }

                this.programmerObject.name = [profile.firstName, profile.lastName].filter(Boolean).join(" ");
                this.programmerObject.email = profile.email;
            }
        }
    }
};
</script>

<style lang="scss">
@import "@web/sass/variables.scss";

.kod-programmer-questionaire {
    @include margin-top-multiplier(4);
    @include padding-top-multiplier(7);
    @include padding-bottom-multiplier(4);
    @include padding-left-multiplier(8);
    @include padding-right-multiplier(8);

    width: 51.5rem;

    background: white;
    p.small-text {
        &:first-child {
            cursor: pointer;
            @include margin-bottom-multiplier(1);
            &:hover {
                text-decoration: underline;
            }
        }
    }
    hr {
        border: unset;
        border-bottom: 0.063rem solid $koderia-border-grey;
    }
    h3 {
        @include margin-bottom-multiplier(2);
    }
    p.body-text {
        @include margin-bottom-multiplier(6);
    }
    // overriding margins
    .custom-autocomplete {
        margin-top: 0;
        padding-top: 0 !important;
    }
    // intro - second step
    .kod-prax-technology-question {
        @include margin-top-multiplier(8);
        &.remove-margin {
            @include margin-top-multiplier(0);
        }
        p.title-text {
            > span {
                color: $koderia-green;
            }
        }

        .v-slider--horizontal {
            margin-left: 0;
            margin-right: 0;
        }
    }
    // additional questions for tester/programmer
    .bonus-questions {
        &.add-margin {
            @include margin-top-multiplier(8);
        }

        p.title-text {
            @include margin-bottom-multiplier(2);
        }
        .kod-radio-group {
            @include margin-bottom-multiplier(7);
            padding-top: 0;

            &:last-child {
                @include margin-bottom-multiplier(0);
            }
            &:last-child.remove-no-margin {
                @include padding-bottom-multiplier(7);
            }
            label {
                color: $koderia-black;
            }
            .v-input__slot {
                margin-bottom: 0;
            }
            .v-messages {
                display: none;
            }
        }
    }
    h4 {
        @include margin-bottom-multiplier(1);
    }
    .questionaire-contact-info {
        @include margin-top-multiplier(3);
        @include margin-bottom-multiplier(6);

        display: flex;
        flex-direction: row;

        > div {
            width: 50%;
            &:first-child {
                @include padding-right-multiplier(2);
            }
            &:last-child {
                @include padding-left-multiplier(2);
            }
        }
    }
    .kod-gdpr-checkbox {
        @include margin-top-multiplier(3);
    }
    .questionaire-actions {
        text-align: center;
        hr {
            @include margin-top-multiplier(3);
        }
        button {
            @include margin-top-multiplier(4);
            padding: 0.625rem 5.5rem !important;
        }
    }
}
</style>
